<template>
  <div class="notice-dialog">
    <el-dialog  :fullscreen="dialogFull" :visible.sync='dialogs' width="800px" :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
              添加通知公告
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>

      <el-form
      label-width="80px"
      :model="form"
      class="add-form"
      label-position="top"
      :rules="rules"
      ref="dialog">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="公告标题" prop='name'>
              <el-input class='form-input' v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公告类型" prop='type'>
              <el-select class='form-input' v-model="form.type" placeholder="公告状态">
                <el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row >
        <el-form-item label="内容" prop='content'>
          <Editor
            :init="setting"
            :value="form.content"
            v-model="form.content"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addNotice } from '@/api/saasManage'
import Editor from '@tinymce/tinymce-vue'
// import { Tag, RadioGroup } from 'element-ui';
// import {formRequest} from "@/api/home";
import { getToken } from '@/utils/tools';
import { axios } from '@/apis/http/request';
export default {
  name:'AddDialog',
  components: {
    Editor,
  },
  props: {
     dialog:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      token: getToken(),
      dialogs:false,
      dialogFull:false,
      form:{
        content:'', // 富文本框
        name:'',//公告标题
        type:'' //公告类型
      },
      rules:{
        type:[
           { required: true, message: '公告类型不能为空', trigger: 'blur' },
        ],
        name:[
           { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        content:[
           { required: true, message: '富文本内容不能为空', trigger: 'blur' },
        ],

      },
      options:[{
        value: '通知',
        label: '通知'
      }, {
        value: '公告',
        label: '公告'
      }],
      setting: {
        language: 'zh_CN',
        menubar: false, // 禁用菜单栏
        plugins: 'print preview searchreplace fullscreen autolink image link media code' +
          ' codesample table charmap hr pagebreak nonbreaking advlist lists textpattern help',
        toolbar: `bold italic underline strikethrough forecolor backcolor link autolink
        | alignleft aligncenter alignright alignjustify outdent indent bullist numlist
        | formatselect fontselect fontsizeselect lineheight
        | blockquote subscript superscript removeformat image media table
        | hr insertdatetime preview code codesample visualblocks searchreplace
        | undo redo fullscreen`,
        toolbar_mode: 'sliding',
        placeholder: '请输入正文',
        branding: false,
        autosave_ask_before_unload: false,
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;' +
          '苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;' +
          'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;' +
          'Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;' +
          'Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;' +
          'Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;' +
          'Wingdings=wingdings,zapf dingbats;' +
          '知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, ' +
          'Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;' +
          '小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif',
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        formats: {
          h1: { block: 'h1', classes: 'aiming-point' },
          h2: { block: 'h2', classes: 'aiming-point' },
          h3: { block: 'h3', classes: 'aiming-point' },
          h4: { block: 'h4', classes: 'aiming-point' },
          h5: { block: 'h5', classes: 'aiming-point' }
        },
        // 图片上传
        images_upload_handler: function (blobInfo, success, failure) {
          if (blobInfo.blob().size / 1024 / 1024 > 2) {
            failure('上传失败，图片大小请控制在 2M 以内')
          } else {
            const data = new FormData()
            data.append('file', blobInfo.blob())
            axios.request({url: `/api/mapi?__method_name__=file&token=${getToken()}`,	method: 'post',data: data}).then(res => {
              if (res.data.code === 200) {
                console.log(res)
                success(res.data.data.url.replace('_thumb', ''))
              } else {
                failure('上传失败')
              }
            })
          }
        },
        height: 260
      },
    };
  },
  watch: {
    dialog(newVal){
      this.dialogs = newVal;
    },
  },
  methods: {
    /**
     * @desc: 保存
     */
    onSave(){
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
          addNotice(this.form).then(res=>{
            if(res.data && res.data.code !== 200){
              return false;
            }
            this.$emit('dialogSave');
            this.form = {
              content:0, // 富文本框
              name:'',//公告标题
              type:'' //公告类型
            };
          }).catch(() => {
            this.$message.error(`操作错误`);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.dialogs = false;
      this.form={
        content:'', // 富文本框
        name:'',//公告标题
        type:'' //公告类型
      };
      this.$emit('dialogClose');
    },
  },
};
</script>
<style lang="less">
.notice-dialog{
  .add-form{
    height:410px;
    overflow:auto;
    padding-bottom: 10px;
  }
}

</style>

<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
